import { PathwayAdminDropdownFragment$key } from "@/admin/pathways/__generated__/PathwayAdminDropdownFragment.graphql"
import usePathwayAdminDropdownItems from "@/admin/pathways/hooks/usePathwayAdminDropdownItems"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

type Props = TestIDProps & {
  productKey: PathwayAdminDropdownFragment$key
  classes?: ClassNameMap<"button">
}

export default function PathwayAdminDropdown(props: Props) {
  const { testid = "PathwayAdminDropdown", productKey, classes: customClasses } = props

  const pathwayProduct = useFragment<PathwayAdminDropdownFragment$key>(
    graphql`
      fragment PathwayAdminDropdownFragment on Product {
        id
        slug
        ...usePermissionsFragment
        ...usePathwayAdminDropdownItemsFragment
      }
    `,
    productKey
  )

  const dropdownItems = usePathwayAdminDropdownItems({
    pathwayProductKey: pathwayProduct,
    testid: props.testid,
  })

  if (!dropdownItems.length) return null

  return (
    <DiscoMoreActionsDropdown
      testid={`${testid}.${pathwayProduct.slug}`}
      rotateIcon
      moreActionsButtonClassName={customClasses?.button}
    >
      {dropdownItems}
    </DiscoMoreActionsDropdown>
  )
}
