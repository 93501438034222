import usePaginatedProductList from "@/admin/community/product-list/hooks/usePaginatedProductList"
import { DeletePathwayButtonMutation } from "@/admin/pathways/__generated__/DeletePathwayButtonMutation.graphql"
import { DeletePathwayButton_productFragment$key } from "@/admin/pathways/__generated__/DeletePathwayButton_productFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoConfirmationModal from "@disco-ui/modal/DiscoConfirmationModal"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { useFragment, useMutation } from "react-relay"
import { graphql } from "relay-runtime"

type DeletePathwayButtonProps = {
  children: OverridableDiscoButtonChildren
  pathwayProductKey: DeletePathwayButton_productFragment$key
  onDelete?: VoidFunction
} & TestIDProps

function DeletePathwayButton({
  testid = "DeletePathwayButton",
  pathwayProductKey,
  onDelete,
  children,
}: DeletePathwayButtonProps) {
  const [showModal, setShowModal] = useState(false)
  const activeOrganization = useActiveOrganization()!
  const experienceLabel = useLabel("admin_experience")

  const pathwayProduct = useFragment(
    graphql`
      fragment DeletePathwayButton_productFragment on Product {
        id
        name
        type
        ...usePermissionsFragment
      }
    `,
    pathwayProductKey
  )

  const permissions = usePermissions(pathwayProduct)

  const [deletePathway, isLoading] = useMutation<DeletePathwayButtonMutation>(graphql`
    mutation DeletePathwayButtonMutation($id: ID!) {
      response: deletePathway(pathwayProductId: $id) {
        node {
          id
          product {
            id
            organization {
              ...ProductsSidebar_NavSectionsFragment
              ...ProductsSidebarList_OrganizationFragment
            }
          }
        }
        errors {
          field
          message
        }
      }
    }
  `)

  if (!permissions.has("pathway.delete")) return null

  return (
    <>
      <OverridableDiscoButton onClick={openModal}>{children}</OverridableDiscoButton>
      <DiscoConfirmationModal
        testid={`${testid}.ConfirmationModal`}
        isOpen={showModal}
        onClose={closeModal}
        title={`Delete Pathway`}
        description={`Are you sure you want to delete the Pathway "${pathwayProduct.name}"? Pathway progress and certificates will be lost. Members will remain enrolled in any Pathway ${experienceLabel.plural} they have already registered for. This action cannot be undone.`}
        modalContentLabel={"Delete Pathway Confirmation Modal"}
        typeToConfirmText={pathwayProduct.name}
        confirmButtonProps={{
          onClick: handleDelete,
          disabled: isLoading,
          shouldDisplaySpinner: isLoading,
          children: "Yes, delete it",
        }}
      />
    </>
  )

  function openModal() {
    setShowModal(true)
  }

  function closeModal() {
    setShowModal(false)
  }

  function handleDelete() {
    deletePathway({
      variables: { id: pathwayProduct.id },
      onCompleted({ response }) {
        if (response?.errors?.length) {
          return displayErrorToast(response.errors[0].message)
        }
        usePaginatedProductList.invalidatePathwaysConnections(activeOrganization.id)
        displaySuccessToast({
          message: `Pathway has been deleted`,
          testid: "DeletePathwayButton.success-toast",
        })
        onDelete?.()
        closeModal()
      },
      onError(err) {
        console.error(err)
        displayErrorToast("An unknown error occurred. Please try again.")
      },
    })
  }
}

export default DeletePathwayButton
