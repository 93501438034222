import DeletePathwayButton from "@/admin/pathways/DeletePathwayButton"
import { usePathwayAdminDropdownItemsFragment$key } from "@/admin/pathways/hooks/__generated__/usePathwayAdminDropdownItemsFragment.graphql"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isProductLevelRoute } from "@/core/route/util/routeUtils"
import { DiscoIcon } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"

type Props = {
  pathwayProductKey: usePathwayAdminDropdownItemsFragment$key
} & TestIDProps

function usePathwayAdminDropdownItems({
  testid = "usePathwayAdminDropdownItems",
  pathwayProductKey,
}: Props) {
  const settingsDrawer = useGlobalDrawer("pathwaySettings")
  const certificateDrawer = useGlobalDrawer("certificates")
  const history = useHistory()

  const pathwayProduct = useFragment(
    graphql`
      fragment usePathwayAdminDropdownItemsFragment on Product {
        id
        slug
        ...usePermissionsFragment
        ...DeletePathwayButton_productFragment
      }
    `,
    pathwayProductKey
  )

  const permissions = usePermissions(pathwayProduct)
  const items = []
  if (permissions.has("registration.manage")) {
    items.push(
      <DiscoDropdownItem
        key={"registration"}
        testid={`${testid}.registration`}
        to={generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
          productSlug: pathwayProduct.slug,
        })}
        title={"Registration Page"}
        icon={<DiscoIcon icon={"ticket"} />}
      />
    )
  }
  if (permissions.has("pathways.manage")) {
    items.push(
      <DiscoDropdownItem
        key={"settings"}
        testid={`${testid}.settings`}
        onClick={() => settingsDrawer.open({ pathwayProductId: pathwayProduct.id })}
        title={"Settings"}
        icon={<DiscoIcon icon={"settings"} />}
      />
    )
  }
  if (permissions.has("members.manage")) {
    items.push(
      <DiscoDropdownItem
        key={"reports"}
        testid={`${testid}.reports`}
        to={generatePath(ROUTE_NAMES.ADMIN.INSIGHTS.PATHWAYS.PATHWAY, {
          productSlug: pathwayProduct.slug,
        })}
        title={"Reports"}
        icon={<DiscoIcon icon={"chart-bar"} />}
      />
    )
  }
  if (permissions.has("certificate_templates.manage")) {
    items.push(
      <DiscoDropdownItem
        key={"certificates"}
        testid={`${testid}.certificates`}
        onClick={() =>
          certificateDrawer.open({ drawerCertificatesProductId: pathwayProduct.id })
        }
        title={"Certificates"}
        icon={<DiscoIcon icon={"achievement"} />}
      />
    )
  }

  if (permissions.has("pathway.delete")) {
    items.push(
      <DeletePathwayButton
        pathwayProductKey={pathwayProduct}
        onDelete={() => {
          if (
            pathwayProduct.slug &&
            isProductLevelRoute(location.href, { productSlug: pathwayProduct.slug })
          ) {
            history.replace(ROUTE_NAMES.COMMUNITY.EXPERIENCES.ROOT)
          }
        }}
      >
        {(buttonProps) => (
          <DiscoDropdownItem
            {...buttonProps}
            title={"Delete"}
            icon={<DiscoIcon icon={"trash"} />}
            testid={`${testid}.delete`}
          />
        )}
      </DeletePathwayButton>
    )
  }

  return items
}

export default usePathwayAdminDropdownItems
